class Heap{
    constructor(){
        this.items = [];
    }

    getSize(){
        return this.items.length;
    }

    insert(key, id, item){
        this.items.push({
            key: key,
            value: item,
            id: id
        });
        this.siftUp(this.items.length - 1);
    }

    extractMin(){
        this.exchange(0, this.items.length - 1);
        let min = this.items.pop();
        this.heapify(0);
        return min.value;
    }

    changePriority(id, key){
        for(let i = 0; i < this.items.length; i++){
            if(this.items[i].id == id){
                this.items[i].key = key;
                this.heapify(i);
                this.siftUp(i);
                break;
            }
        }
    }

    exchange(i, j){
        let temp = this.items[i];
        this.items[i] = this.items[j];
        this.items[j] = temp;
    }

    heapify(i){
        let left = 2*i + 1;
        let right = 2*i + 2;
        let min = i;

        if(left < this.items.length && this.items[left].key < this.items[min].key){
            min = left;
        } 
        if(right < this.items.length && this.items[right].key < this.items[min].key){
            min = right;
        }
        if(min != i){
            this.exchange(i, min);
            this.heapify(min);
        }
    }

    siftUp(i){
        if(i == 0) return;
        if(this.items[Math.floor((i-1)/2)].key > this.items[i].key){
            this.exchange(Math.floor((i-1)/2), i);
            this.siftUp(Math.floor((i-1)/2));
        }
    }

    isEmpty(){
        return this.items.length == 0;
    }

    clear(){
        this.items.splice(0, this.items.length)
    }
}

export default Heap;