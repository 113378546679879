<template>
  <div class="nav-dropdown">
    <div v-for="(item, index) in actions" :key="index" 
        @click="item.onClick()" :class="{'nav-dropdown-item': true, 
        'disabled': item.disabled==null ? false : item.disabled}">
      {{item.title}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavDropdown',
  methods: {
    showAt(x, y, el, actions){
      this.$el.style.top = y+"px";
      this.$el.style.left = x+"px";
      if(el == this.lastElement){
        this.$el.classList.remove('open');
        this.lastElement = null;
      }
      else{ 
        this.$el.classList.add('open');
        this.lastElement = el;
      }
      this.handled = true;
      this.actions = actions;
    }
  },
  mounted(){
    window.addEventListener('click',()=>{
      if(!this.handled) {
        this.$el.classList.remove('open');
        this.lastElement = null
      }
      this.handled = false;
    })
  },
  data() {
    return {
      actions: [],
      handled: false,
      lastElement: null
    }
  },
}
</script>

<style scoped>
  .nav-dropdown{
    width: 144px;
    background: #FFF;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.25);
    position: absolute;
    left: 64px;
    top: 64px;
    border-radius: 4px;
    transform: scaleY(0);
    transition: transform 0.05s;
    transform-origin: top;
    display: flex;
    flex-direction: column;
  }

  .nav-dropdown.open{
    transform: scaleY(1);
  }

  .nav-dropdown-item{
    padding: 12px 20px;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    transition: background 0.25s;
    user-select: none;
    font-weight: 500;
  }

  .nav-dropdown-item:hover{
    background: rgba(0,0,0,0.1);
  }

  .nav-dropdown-item:active{
    background: rgba(0,0,0,0.2);
  }

  .nav-dropdown-item.disabled{
    cursor: default;
    color: rgba(0,0,0,0.5);
    background: transparent !important;
  }
</style>
