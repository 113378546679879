<template>
  <div class="main-canvas-outer">
    <canvas id="main-canvas" width="500" height="500"></canvas>
  </div>
</template>

<script>
/*eslint no-unused-vars: "off"*/
/*eslint no-constant-condition: "off"*/

import Stack from './../ds/stack';
import uniqueId from '../utils';

let canvas;
let ctx;
let cameraX = 0;
let cameraY = 0;
let cameraZoom = 1;
let minZoom = 0.25;
let maxZoom = 4;
let scrollSensitivity = 0.001;

let isDragging = false;
let dragStartX = 0;
let dragStartY = 0;
let dragStartCX = 0;
let dragStartCY = 0;
let dragRoom = null;
let dragRoomIW = 0;
let dragRoomIH = 0;
let dragRoomIX = 0;
let dragRoomIY = 0;
let dragHandle = null;

let mouseX = 0, mouseY = 0;
let mouseRawX = 0, mouseRawY = 0;

let lineStart = null;
let lineEnd = null;

let listeners = {};

const squareSize = 28;

export default {
  name: 'PlanCanvas',
  components: {},
  props: ['plan', 'undoStack', 'route'],
  methods: {
    initialize(){
      window.addEventListener('resize', this.onResize);
      window.addEventListener('mousemove', ()=>{});
      this.onResize();
    },
    onResize(){
      canvas.width = canvas.clientWidth;
      canvas.height = canvas.clientHeight;
    },
    registerShortcuts(){
      canvas.addEventListener('wheel', (event)=>{
        cameraZoom -= event.deltaY*scrollSensitivity;
        cameraZoom = Math.min(maxZoom, cameraZoom);
        cameraZoom = Math.max(minZoom, cameraZoom);
      })

      listeners['keydown'] = (event)=>{
        if(event.repeat) return;
        if((event.key == 'd' || event.key == 'D') && event.ctrlKey){
          if(this.plan.firstSelection != null){
            event.preventDefault()
            this.$root.$emit('action-duplicate');
          }
          return false;
        }
        if((event.key == 'z' || event.key == 'Z') && event.ctrlKey){
          if(!this.undoStack.isEmpty()){
            event.preventDefault()
            this.$root.$emit('action-undo', this.plan.firstSelection);
          }
          return false;
        }

        if((event.key == 'c' || event.key == 'C') && event.ctrlKey){
          if(this.plan.firstSelection != null){
            event.preventDefault()
            this.$root.$emit('action-copy');
          }
          return false;
        }

        if((event.key == 'x' || event.key == 'X') && event.ctrlKey){
          if(this.plan.firstSelection != null){
            event.preventDefault()
            this.$root.$emit('action-cut');
          }
          return false;
        }

        if((event.key == 'v' || event.key == 'V') && event.ctrlKey){
          event.preventDefault()
          this.$root.$emit('action-paste', mouseX / squareSize, mouseY / squareSize);
          return false;
        }

        if((event.key == 's' || event.key == 'S') && event.ctrlKey){
          event.preventDefault()
          this.$root.$emit('action-save');
          return false;
        }

        if((event.key == 'o' || event.key == 'O') && event.ctrlKey){
          event.preventDefault()
          this.$root.$emit('action-open');
          return false;
        }

        if((event.key == 'e' || event.key == 'E') && event.ctrlKey){
          event.preventDefault()
          this.$root.$emit('fire-escape', this.plan.firstSelection);
          return false;
        }
      }

      window.addEventListener('keydown', listeners['keydown'])

      listeners['keyup'] = (event)=>{
        if(event.key == 'Escape'){
          this.route.found = false;
          this.plan.firstSelection = null;
          this.plan.secondSelection = null;
          return false;
        }
        if(event.key == 'Delete'){
          if(this.plan.firstSelection != null){
            if(this.plan.secondSelection != null){
              this.$root.$emit('action-delete-edge');
            } else{
              this.$root.$emit('action-delete-room');
            }
          }
          return false;
        }
        return true;
      }

      window.addEventListener('keyup', listeners['keyup'])

      canvas.addEventListener('pointerdown', (event)=>{
        let ex = cameraX + (event.offsetX - canvas.width/2)/cameraZoom;
        let ey = cameraY + (event.offsetY - canvas.height/2)/cameraZoom;
        
        isDragging = true;
        dragStartX = event.offsetX;
        dragStartY = event.offsetY;
        dragStartCX = cameraX;
        dragStartCY = cameraY;

        if(this.plan.firstSelection != null){
          dragHandle = this.getDragHandle(event.offsetX, event.offsetY);
          dragRoomIW = this.plan.firstSelection.width * squareSize;
          dragRoomIH = this.plan.firstSelection.height * squareSize;
          dragRoomIX = this.plan.firstSelection.x * squareSize;
          dragRoomIY = this.plan.firstSelection.y * squareSize;
        }

        dragRoom = dragHandle!=null ? this.plan.firstSelection : this.getRoomAtPosition(ex, ey);
        if(dragRoom != null){
          dragStartCX = dragRoom.x * squareSize;
          dragStartCY = dragRoom.y * squareSize;

          this.route.found = false;

          if(!event.ctrlKey) {
            this.plan.firstSelection = dragRoom;
            this.plan.secondSelection = null;
          }
          this.undoStack.push({
            type: 'resize-room',
            x: this.plan.firstSelection.x,
            y: this.plan.firstSelection.y,
            width: this.plan.firstSelection.width,
            height: this.plan.firstSelection.height,
            room: this.plan.firstSelection
          });
        }
      })

      canvas.addEventListener('pointermove', (event)=>{
        mouseX = cameraX + (event.offsetX - canvas.width/2)/cameraZoom;
        mouseY = cameraY + (event.offsetY - canvas.height/2)/cameraZoom;

        /*lineEnd = [mouseX, mouseY]

        if(true) return;*/
        if(isDragging){
          if(dragRoom == null){
            cameraX = dragStartCX + (dragStartX-event.offsetX)/cameraZoom;
            cameraY = dragStartCY + (dragStartY-event.offsetY)/cameraZoom;
          }else{
            if(dragHandle == null){
              dragRoom.x = (dragStartCX - (dragStartX-event.offsetX)/cameraZoom) / squareSize;
              dragRoom.y = (dragStartCY - (dragStartY-event.offsetY)/cameraZoom) / squareSize;
            }else{
              if(dragHandle == 'bottom-right'){
                dragRoom.width = (dragRoomIW - (dragStartX-event.offsetX)/cameraZoom) / squareSize;
                dragRoom.height = (dragRoomIH - (dragStartY-event.offsetY)/cameraZoom) / squareSize;
              } else if(dragHandle == 'top-right'){
                dragRoom.width = (dragRoomIW - (dragStartX-event.offsetX)/cameraZoom) / squareSize;
                dragRoom.height = (dragRoomIH + (dragStartY-event.offsetY)/cameraZoom) / squareSize;
                dragRoom.y = (dragRoomIY - (dragStartY-event.offsetY)/cameraZoom) / squareSize;
              } else if(dragHandle == 'bottom-left'){
                dragRoom.width = (dragRoomIW + (dragStartX-event.offsetX)/cameraZoom) / squareSize;
                dragRoom.height = (dragRoomIH - (dragStartY-event.offsetY)/cameraZoom) / squareSize;
                dragRoom.x = (dragRoomIX - (dragStartX-event.offsetX)/cameraZoom) / squareSize;
              } else if(dragHandle == 'top-left'){
                dragRoom.width = (dragRoomIW + (dragStartX-event.offsetX)/cameraZoom) / squareSize;
                dragRoom.height = (dragRoomIH + (dragStartY-event.offsetY)/cameraZoom) / squareSize;
                dragRoom.x = (dragRoomIX - (dragStartX-event.offsetX)/cameraZoom) / squareSize;
                dragRoom.y = (dragRoomIY - (dragStartY-event.offsetY)/cameraZoom) / squareSize;
              }
              if(dragHandle != null){
                dragRoom.width = Math.max(dragRoom.width, 4);
                dragRoom.height = Math.max(dragRoom.height, 4);
              }
            }
          }
        }
        mouseRawX = event.offsetX;
        mouseRawY = event.offsetY;
      })
      canvas.addEventListener('pointerup', (event)=>{
        if(Math.pow(event.offsetX - dragStartX, 2) + Math.pow(event.offsetY - dragStartY, 2) < 2) {
          let ex = cameraX + (event.offsetX - canvas.width/2)/cameraZoom;
          let ey = cameraY + (event.offsetY-canvas.height/2)/cameraZoom;
          
          if(event.ctrlKey && this.plan!=null){
            let secondRoom = this.getRoomAtPosition(ex, ey);
            if(this.plan.firstSelection != null && secondRoom != null && this.plan.firstSelection != secondRoom){
              this.plan.secondSelection = this.plan.firstSelection;
              this.plan.firstSelection = secondRoom;
              this.$root.$emit('action-add-edge', this.plan.firstSelection, this.plan.secondSelection,
                event.altKey ? (Math.floor(Math.random()*21)) : 0);
              this.undoStack.push({
                type: 'null'
              })
              isDragging = false;
              dragRoom = null;
              dragHandle = null;
              return; 
            }
          }

          if(event.shiftKey && this.plan!=null){
            let secondRoom = this.getRoomAtPosition(ex, ey);
            if(secondRoom != null && this.plan.fire_escape_room != secondRoom.id){
              this.undoStack.push({
                type: 'mark-as-escape',
                previous: this.plan.fire_escape_room
              })
              this.plan.fire_escape_room = secondRoom.id;
              this.plan.firstSelection = secondRoom;
              isDragging = false;
              dragRoom = null;
              dragHandle = null;
              return; 
            }
          }

          let previousSelection = this.plan.firstSelection;
          this.plan.firstSelection = this.getRoomAtPosition(ex, ey);
          this.plan.secondSelection = null;

          if(this.plan.firstSelection==null){
            this.$root.$emit('action-create-room', ex / squareSize, ey / squareSize);
            
            if(event.ctrlKey && previousSelection!=null){
              this.plan.secondSelection = previousSelection;
              this.$root.$emit('action-add-edge', this.plan.firstSelection, this.plan.secondSelection,
                event.altKey ? (Math.floor(Math.random()*21)) : 0);
            }
          }else{
            this.undoStack.pop();
          }
        }
        isDragging = false;
        dragRoom = null;
        dragHandle = null;
      })
    },
    drawGrid() {
      let width = canvas.width;
      let height = canvas.height;
      let scaledSquareSize = scaleDimension(squareSize);
      ctx.strokeStyle = 'lightgrey';
      ctx.lineWidth = 1;
      ctx.beginPath()
      let p = toScreenPosition([0,0])
      for (let x = p[0]; x <= width; x += scaledSquareSize) {
        ctx.moveTo(x, 0)
        ctx.lineTo(x, height)
      }
      for (let y = p[1]; y <= height; y += scaledSquareSize) {
          ctx.moveTo(0, y)
          ctx.lineTo(width, y)
      }

      for (let x = p[0] - scaledSquareSize; x >=0; x -= scaledSquareSize) {
          ctx.moveTo(x, 0)
          ctx.lineTo(x, height)
      }
      for (let y = p[1] - scaledSquareSize; y >= 0; y -= scaledSquareSize) {
          ctx.moveTo(0, y)
          ctx.lineTo(width, y)
      }
      ctx.stroke()
    },

    redraw(){
      ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)
      this.drawGrid();
      if(this.plan==null){ 
        requestAnimationFrame(this.redraw)
        return;
      }
      
      for(let i=0; i<this.plan.rooms.length; i++){
        let room = this.plan.rooms[i];
        let [x, y] = toScreenPosition([room.x, room.y])
        let scaledWidth = scaleDimension(room.width) * squareSize;
        let scaledHeight = scaleDimension(room.height) * squareSize;
        ctx.strokeStyle = 'black';
        ctx.fillStyle = 'black';
        let arcSize = scaleDimension(4);

        for (let i = 0; i < this.plan.paths[room.id].length; i++) {
          const second = this.getRoomById(this.plan.paths[room.id][i]);
          if(second == null) continue;
          
          let [start, end] = getEdgePoints2(room, second);
          start = toScreenPosition(start)
          end = toScreenPosition(end)
          ctx.strokeStyle = 'grey';
          let color = 'black';
          if((room == this.plan.firstSelection && second == this.plan.secondSelection) ||
            (second == this.plan.firstSelection && room == this.plan.secondSelection)){
            color = '#FB8500';
            ctx.strokeStyle = color;
          }

          if(this.route.found && this.liesOnRoute(room.id, second.id)){
            color = 'red';
            ctx.strokeStyle = color;
          }
          ctx.beginPath();
          ctx.lineWidth = scaleDimension(2);
          ctx.moveTo(start[0], start[1]);
          ctx.lineTo(end[0], end[1]);
          ctx.stroke();

          ctx.fillStyle = 'white';
          ctx.beginPath()
          ctx.arc((start[0]+end[0])/2, (start[1]+end[1])/2, scaleDimension(20), 0, Math.PI*2)
          ctx.fill()

          let props = this.getEdgeProperties(room.id, second.id);
          if(props!=null){
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.font = +scaleDimension(20)+"px Arial";
            ctx.fillStyle = color;
            ctx.fillText(props.length, (start[0]+end[0])/2, (start[1]+end[1])/2);
          }
        }

        let nodeColor = 'black';
        if(this.plan.firstSelection == room || this.plan.secondSelection == room){
          nodeColor = '#FB8500';
        }
        if(room.id == this.plan.fire_escape_room){
          nodeColor = 'green';
        }

        ctx.strokeStyle = nodeColor;
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.lineWidth = scaleDimension(2);
        ctx.rect(x, y, scaledWidth, scaledHeight);
        ctx.stroke();

        if(room==this.plan.firstSelection || room==this.plan.secondSelection){
          ctx.strokeStyle = '#FB8500';
          ctx.fillStyle = '#FB8500';
          ctx.beginPath()
          ctx.arc(x, y, arcSize, 0, Math.PI*2);
          ctx.fill()
          ctx.beginPath()
          ctx.arc(x + scaledWidth, y, arcSize, 0, Math.PI*2);
          ctx.fill()
          ctx.beginPath()
          ctx.arc(x, y + scaledHeight, arcSize, 0, Math.PI*2);
          ctx.fill()
          ctx.beginPath()
          ctx.arc(x + scaledWidth, y + scaledHeight, arcSize, 0, Math.PI*2);
          ctx.fill()
        }

        ctx.strokeStyle = nodeColor;
        ctx.fillStyle = nodeColor;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = scaleDimension(20)+"px Arial";
        ctx.fillText(room.name, x+scaledWidth/2, y+scaledHeight/2, scaledWidth);
      }
      ctx.textAlign = "left";
      ctx.textBaseline = "bottom";
      ctx.font = "14px Arial";
      ctx.fillStyle = "black";
      ctx.fillText((mouseX / squareSize).toFixed(4)+", "+(mouseY / squareSize).toFixed(4), 
          mouseRawX, mouseRawY)
      drawLine();
      requestAnimationFrame(this.redraw)
    },
    loadPlan(plan){
      /*this.plan.rooms = []
      this.plan.firstSelection = null;
      this.this.plan = plan;
      if(plan != null)
        this.plan.rooms = plan.this.plan.rooms;*/
      this.undoStack.clear();
    },
    getRoomAtPosition(x, y){
      for(let i = 0; i < this.plan.rooms.length; i++){
        let room = this.plan.rooms[i];
        if(room.x  * squareSize < x && room.y * squareSize < y && x < room.x * squareSize + 
            room.width * squareSize && y < room.y * squareSize + room.height * squareSize){
          return room;
        }
      }
      return null;
    },
    getRoomById(id){
      for(let i = 0;i < this.plan.rooms.length; i++){
        let room = this.plan.rooms[i];
        if(room.id === id){
          return room;
        }
      }
      return null;
    },
    getDragHandle(x, y){
      let scaledRadius = scaleDimension(4);
      let screenPos = toScreenPosition([this.plan.firstSelection.x, this.plan.firstSelection.y]);
      let scaledWidth = scaleDimension(this.plan.firstSelection.width) * squareSize;
      let scaledHeight = scaleDimension(this.plan.firstSelection.height) * squareSize;

      if(distance(screenPos[0], screenPos[1], x, y) < scaledRadius){
        return "top-left";
      }
      if(distance(screenPos[0] + scaledWidth, screenPos[1], x, y) < scaledRadius){
        return "top-right";
      }
      if(distance(screenPos[0], screenPos[1] + scaledHeight, x, y) < scaledRadius){
        return "bottom-left";
      }
      if(distance(screenPos[0] + scaledWidth, screenPos[1] + scaledHeight, x, y) < scaledRadius){
        return "bottom-right";
      }
      return null;
    },
    getEdgeProperties(r1, r2){
      let temp = this.plan.path_info[r1+"+"+r2]
      if(temp!=null) return temp;
      temp = this.plan.path_info[r2+"+"+r1]
      if(temp!=null) return temp;
      return {};
    },
    liesOnRoute(r1, r2){
      for(let i = 0; i < this.route.path.length-1; i++){
        if(this.route.path[i] == r1 && this.route.path[i+1] == r2) return true;
        if(this.route.path[i] == r2 && this.route.path[i+1] == r1) return true;
      }
      return false;
    }
  },
  mounted () {
    canvas = document.getElementById('main-canvas');
    ctx = canvas.getContext('2d')
    this.registerShortcuts()
    this.initialize()
    this.redraw()
  },
  beforeDestroy() {
    window.removeEventListener('keydown', listeners['keydown'])
    window.removeEventListener('keyup', listeners['keyup'])
  },
}

let scaleDimension = (val)=>{
  return val*cameraZoom;
}

let toScreenPosition = (point)=>{
  return [
    canvas.width/2 + (-cameraX + point[0] * squareSize) * cameraZoom,
    canvas.height/2 + (-cameraY + point[1] * squareSize) * cameraZoom,
  ];
}

let drawLine = ()=>{
  if(lineStart==null) return;
  let start = toScreenPosition(lineStart);
  let end = toScreenPosition(lineEnd);
  let middle = [];
  ctx.strokeStyle = 'black';
  ctx.lineWidth = 4;

  middle[0] = end[0];
  middle[1] = start[1];

  ctx.beginPath()
  ctx.moveTo(start[0], start[1]);
  ctx.lineTo(middle[0], middle[1]);
  ctx.lineTo(end[0], end[1]);
  ctx.stroke()
}


let distance = (x1, y1, x2, y2)=>{
  return Math.sqrt(Math.pow(x1-x2, 2) + Math.pow(y1-y2, 2));
}

let getEdgePoints = (startRoom, endRoom)=>{
  let startCenter = [
    startRoom.x + startRoom.width / 2,
    startRoom.y + startRoom.height / 2
  ];

  let endCenter = [
    endRoom.x + endRoom.width / 2,
    endRoom.y + endRoom.height / 2
  ];

  let startPoint = [startCenter[0], startCenter[1]];
  let currentDistance = distance(endCenter[0], endCenter[1], startPoint[0], startPoint[1]);

  let tempDist;
  let tempPoint;

  let possiblePoints = [
    [startRoom.x + startRoom.width/2, startRoom.y],
    [startRoom.x, startRoom.y + startRoom.height/2],
    [startRoom.x + startRoom.width, startRoom.y + startRoom.height/2],
    [startRoom.x + startRoom.width/2, startRoom.y + startRoom.height]
  ]

  for(let i = 0; i < possiblePoints.length; i++){
    tempPoint = possiblePoints[i]
    tempDist = distance(endCenter[0], endCenter[1], tempPoint[0], tempPoint[1]);
    if(tempDist < currentDistance){
      currentDistance = tempDist;
      startPoint = tempPoint;
    }
  }

  let endPoint = [endCenter[0], endCenter[1]];
  currentDistance = distance(startCenter[0], startCenter[1], endPoint[0], endPoint[1]);

  possiblePoints = [
    [endRoom.x + endRoom.width/2, endRoom.y],
    [endRoom.x, endRoom.y + endRoom.height/2],
    [endRoom.x + endRoom.width, endRoom.y + endRoom.height/2],
    [endRoom.x + endRoom.width/2, endRoom.y + endRoom.height]
  ]

  for(let i = 0; i < possiblePoints.length; i++){
    tempPoint = possiblePoints[i]
    tempDist = distance(startCenter[0], startCenter[1], tempPoint[0], tempPoint[1]);
    if(tempDist < currentDistance){
      currentDistance = tempDist;
      endPoint = tempPoint;
    }
  }

  return [startPoint, endPoint];
}

let getEdgePoints2 = (startRoom, endRoom)=>{
  let startCenter = [
    startRoom.x + startRoom.width / 2,
    startRoom.y + startRoom.height / 2
  ];

  let endCenter = [
    endRoom.x + endRoom.width / 2,
    endRoom.y + endRoom.height / 2
  ];

  let startEdges = [
    [
      [startRoom.x, startRoom.y],
      [startRoom.x + startRoom.width, startRoom.y]
    ],
    [
      [startRoom.x, startRoom.y],
      [startRoom.x, startRoom.y + startRoom.height]
    ],
    [
      [startRoom.x, startRoom.y + startRoom.height],
      [startRoom.x + startRoom.width, startRoom.y + startRoom.height]
    ],
    [
      [startRoom.x + startRoom.width, startRoom.y + startRoom.height],
      [startRoom.x + startRoom.width, startRoom.y]
    ]
  ]
  let startPoint = startCenter;
  for (let i = 0; i < startEdges.length; i++) {
    let p = getIntersectionPoint(startEdges[i][0], startEdges[i][1], startCenter, endCenter);
    if(p!=null){
      startPoint = p;
    }
  }

  let endEdges = [
    [
      [endRoom.x, endRoom.y],
      [endRoom.x + endRoom.width, endRoom.y]
    ],
    [
      [endRoom.x, endRoom.y],
      [endRoom.x, endRoom.y + endRoom.height]
    ],
    [
      [endRoom.x, endRoom.y + endRoom.height],
      [endRoom.x + endRoom.width, endRoom.y + endRoom.height]
    ],
    [
      [endRoom.x + endRoom.width, endRoom.y + endRoom.height],
      [endRoom.x + endRoom.width, endRoom.y]
    ]
  ]
  let endPoint = endCenter;
  for (let i = 0; i < endEdges.length; i++) {
    let p = getIntersectionPoint(endEdges[i][0], endEdges[i][1], startCenter, endCenter);
    if(p!=null){
      endPoint = p;
    }
  }
  return [startPoint,endPoint]
}

let getIntersectionPoint = (a1, a2, b1, b2)=>{
  let b = [a2[0] - a1[0], a2[1] - a1[1]];
  let d = [b2[0] - b1[0], b2[1] - b1[1]];
  var bDotDPerp = b[0] * d[1] - b[1] * d[0];

  if (bDotDPerp == 0)
    return null;
  let c = [b1[0] - a1[0], b1[1] - a1[1]];
  var t = (c[0] * d[1] - c[1] * d[0]) / bDotDPerp;
  if (t < 0 || t > 1){
    return null;
  }
  var u = (c[0] * b[1] - c[1] * b[0]) / bDotDPerp;
  if (u < 0 || u > 1){
    return null;
  }


  return [a1[0] + t * b[0], a1[1] + t * b[1]];
}

</script>

<style>
  #main-canvas{
    width: 100%;
    height: 100%;
  }
</style>