<template>
  <div class="editor">
    <create-popup></create-popup>
    <div class="editor-nav">
      <router-link to="/" class="editor-nav-logo material-icons" >whatshot</router-link>
      <div class="editor-nav-item" @click="showNavDropdown('file',$event)">File</div>
      <div class="editor-nav-item" @click="showNavDropdown('edit',$event)">Edit</div>
      <div class="editor-nav-item" @click="showNavDropdown('graph',$event)">Graph</div>
      <nav-dropdown ref="navdrop"></nav-dropdown>
    </div>
    <div class="editor-content">
      <div class="editor-sidebar">
        <span class="project-title">{{project.name}}</span>
        <span class="project-subtitle">{{project.plans.length}} PLANS</span>

        <div class="search-row">
          <input class="search-input" v-model="searchQuery" placeholder="Search Plans" />
          <div class="add-plan-btn" @click="addPlan()">+</div>
        </div>

        <div class="plan-list">
          <div v-for="(item, index) in searchPlans" v-bind:key="index"
            @click="selectPlan(item)"
            :class="selectedPlan==item ? 'plan-item plan-item-selected' : 'plan-item'" >
            <span class="plan-item-title">{{item.name}}</span>
            <span class="plan-item-subtitle">{{item.rooms.length + 
              (item.rooms.length==1 ? " Room" : " Rooms")}}</span>
          </div>
        </div>
      </div>

      <plan-canvas v-if="selectedPlan!=null" :route="route" :undo-stack="undoStack" :plan="selectedPlan" ref="planCanvas" style="flex-grow: 1"></plan-canvas>

      <div class="editor-window" v-if="selectedPlan!=null && selectedPlan.firstSelection==null && !this.route.found">
        <span class="editor-window-title">Plan Properties</span>
        <div class="editor-window-content">
          <span class="editor-window-label">Name</span>
          <input class="editor-window-input" v-model="selectedPlan.name" placeholder="Plan 0" />

          <span class="editor-window-label">Escape Room</span>
          <input class="editor-window-input" disabled="disabled" :value="selectedPlan.fire_escape_room" placeholder="None" />

          <div class="editor-window-action" @click="deletePlan()">Delete</div>
        </div>
      </div>

      <div class="editor-window" v-if="selectedPlan!=null && selectedPlan.firstSelection!=null && selectedPlan.secondSelection==null && !this.route.found">
        <span class="editor-window-title">Room Properties</span>
        <div class="editor-window-content">
          <span class="editor-window-label">Name</span>
          <input class="editor-window-input" v-model="firstSelection.name" placeholder="Room 0" />

          <div class="editor-window-split">
            <div class="editor-window-split-part">
              <span class="editor-window-label">X</span>
              <input class="editor-window-input" v-model.number="firstSelection.x" placeholder="0"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0" />
            </div>
            <span class="editor-window-split-space"></span>
            <div class="editor-window-split-part">
              <span class="editor-window-label">Y</span>
              <input class="editor-window-input" v-model.number="firstSelection.y" type="number" placeholder="0"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0" />
            </div>
          </div>

          <div class="editor-window-split">
            <div class="editor-window-split-part">
              <span class="editor-window-label">Width</span>
              <input class="editor-window-input" v-model.number="firstSelection.width" type="number" placeholder="0"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0" />
            </div>
            <span class="editor-window-split-space"></span>
            <div class="editor-window-split-part">
              <span class="editor-window-label">Height</span>
              <input class="editor-window-input" v-model.number="firstSelection.height" type="number" placeholder="0"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0" />
            </div>
          </div>

          <div class="editor-window-action" v-if="firstSelection.id!=selectedPlan.fire_escape_room" @click="markAsEscape()">Mark as Escape</div>
        </div>
      </div>

      <div class="editor-window" v-if="selectedPlan!=null && selectedPlan.firstSelection!=null && selectedPlan.secondSelection!=null && !this.route.found">
        <span class="editor-window-title">Edge Properties</span>
        <div class="editor-window-content">
          <span class="editor-window-label">Length</span>
          <input class="editor-window-input" type="number" min="0" v-model="edgeProperties.length" placeholder="0"
                oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : 0" />

          <div class="editor-window-action" @click="deleteEdge()">Delete</div>
        </div>
      </div>

      <div class="editor-window" v-if="selectedPlan!=null && this.route.found">
        <span class="editor-window-title">Escape Route</span>
        <div class="editor-window-content">
          <span class="editor-window-label">Distance</span>
          <input class="editor-window-input" disabled="disabled" :value="this.route.distance" />

          <span class="editor-window-label">Intermediates</span>
          <input class="editor-window-input" disabled="disabled" :value="this.route.path.length-2" />

          <div class="editor-window-action" @click="route.found = false">Ok</div>
        </div>
      </div>
    </div>
    <div id="editor-toast">Undo</div>
  </div>
</template>

<script>
import CreatePopup from '../components/CreatePopup.vue';
import NavDropdown from '../components/NavDropdown.vue';
import PlanCanvas from '../components/PlanCanvas.vue';
import Stack from '../ds/stack';
import Heap from '../ds/heap';
import uniqueId from '../utils';

let canvas;
let ctx;

export default {
  name: 'Editor',
  components: { PlanCanvas, NavDropdown, CreatePopup },
  props: ['iproject'],
  methods: {
    initialize(){
      window.addEventListener('resize', this.onResize);
      this.onResize();
    },
    onResize(){
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      this.drawGrid();
    },
    drawGrid() {
      let width = 1920;
      let height = 960;
      let squareSize = 28;
      ctx.strokeStyle = 'lightgrey';
      ctx.lineWidth = 0.25;
      ctx.beginPath()
      for (var x = 0; x <= width; x += squareSize) {
          ctx.moveTo(x, 0)
          ctx.lineTo(x, height)
      }
      for (var y = 0; y <= height; y += squareSize) {
          ctx.moveTo(0, y)
          ctx.lineTo(width, y)
      }
      ctx.stroke()
    },
    addPlan(){
      if(this.project == null) return;
      let initialRoomId = uniqueId();
      this.project.plans.push({
        name: "Plan "+(this.project.plans.length+1),
        fire_escape_room: "",
        id_counter: 0,
        rooms: [],
        paths: {
          [initialRoomId]: []
        },
        path_info: {},
        firstSelection: null,
        secondSelection: null
      })
    },
    selectPlan(plan){
      this.selectedPlan = plan;
      plan.firstSelection = null;
      if(this.$refs.planCanvas != null)
        this.$refs.planCanvas.loadPlan(plan)
    },
    showNavDropdown(type, event){
      let el = event.target;
      this.$refs.navdrop.showAt(el.getBoundingClientRect().left, 
      el.getBoundingClientRect().top + 44, el, this.getDropdownActions(type))
    },
    getDropdownActions(type){
      if(type === 'file'){
        return [
          {
            title: "New Project",
            onClick: ()=>{
              this.$root.$emit('show-project-popup');
            }
          },
          {
            title: "Project Settings",
            onClick: ()=>{
              this.$root.$emit('show-project-popup', this.project)
            }
          },
          {
            title: "Open",
            onClick: ()=>{
              this.$root.$emit('action-open');
            }
          },
          {
            title: "Save",
            onClick: ()=>{
              this.$root.$emit('action-save');
            }
          },
        ]
      }
      if(type === 'edit'){
        return [
          {
            title: "Undo",
            onClick: ()=>{
              this.$root.$emit('action-undo');
            },
            disabled: this.selectedPlan == null || this.undoStack.isEmpty()
          },
          {
            title: "Cut",
            onClick: ()=>{
              this.$root.$emit('action-cut');
            },
            disabled: this.selectedPlan == null || this.selectedPlan.firstSelection == null
          },
          {
            title: "Copy",
            onClick: ()=>{
              this.$root.$emit('action-copy');
            },
            disabled: this.selectedPlan == null || this.selectedPlan.firstSelection == null
          },
          {
            title: "Paste",
            onClick: ()=>{
              this.$root.$emit('action-paste');
            },
            disabled: this.selectedPlan == null || this.clipboard == null
          },
          {
            title: "Duplicate",
            onClick: ()=>{
              this.$root.$emit('action-duplicate');
            },
            disabled: this.selectedPlan == null || this.selectedPlan.firstSelection == null
          }
        ]
      }
      if(type === 'graph'){
        return [
          {
            title: "Create Room",
            onClick: ()=>{
              this.$root.$emit('action-create-room', 0, 0);
            },
            disabled: this.selectedPlan == null
          },
          {
            title: "Delete Room",
            onClick: ()=>{
              this.$root.$emit('action-delete-room');
            },
            disabled: this.selectedPlan == null || this.selectedPlan.firstSelection == null
          },
          {
            title: "Delete Edge",
            onClick: ()=>{
              this.$root.$emit('action-delete-edge');
            },
            disabled: this.selectedPlan == null || this.selectedPlan.firstSelection == null || this.selectedPlan.secondSelection == null
          },
          {
            title: "Escape From",
            onClick: ()=>{
              this.$root.$emit('fire-escape', this.selectedPlan.firstSelection);
            },
            disabled: this.selectedPlan == null || this.selectedPlan.fire_escape_room == null || this.selectedPlan.firstSelection == null || this.selectedPlan.secondSelection != null
          },
        ]
      }
    },
    deletePlan(){
      this.project.plans.splice(this.project.plans.indexOf(this.selectedPlan), 1);
      this.selectedPlan = this.project.plans[0];
      this.$refs.planCanvas.loadPlan(this.selectedPlan)
    },
    markAsEscape(){
      if(this.selectedPlan!=null && this.selectedPlan.firstSelection!=null){
        this.undoStack.push({
          type: 'mark-as-escape',
          previous: this.selectedPlan.fire_escape_room
        })
        this.selectedPlan.fire_escape_room = this.selectedPlan.firstSelection.id;
      }
    },
    getEdgeProperties(r1, r2){
      let temp = this.selectedPlan.path_info[r1+"+"+r2]
      if(temp!=null) return temp;
      temp = this.selectedPlan.path_info[r2+"+"+r1]
      if(temp!=null) return temp;
      return {};
    },
    deleteEdge(){
      this.$root.$emit('action-delete-edge');
    }
  },

  mounted() {
    if(this.iproject != null){
      this.project = this.iproject;
    }
    this.selectedPlan = this.project.plans[0]
    this.selectedPlan.firstSelection = null;
    this.selectedPlan.secondSelection = null;
    this.$root.$on('select-room', room=>{
      this.selectedPlan.firstSelection = room;
    })

    this.$root.$on('action-create-room', (x, y, width, height, name)=>{
      this.selectedPlan.firstSelection = {
        id: uniqueId(),
        x: (x == null) ? 0 : x,
        y: (y == null) ? 0 : y,
        width: (width == null) ? 4 : width,
        height: (height == null) ? 4 : height,
        name: (name == null) ? "Room "+(this.selectedPlan.rooms.length+1) : name
      }
      this.selectedPlan.rooms.push(this.selectedPlan.firstSelection);
      this.selectedPlan.paths[this.selectedPlan.firstSelection.id] = [];
      this.undoStack.push({
        type: 'create-room',
        room: this.selectedPlan.firstSelection
      });
    })

    this.$root.$on('action-undo', ()=>{
      let action = this.undoStack.pop();
      if(action == null) return;
      if(action.type=='null'){
        action = this.undoStack.pop();
        if(action == null) return;
      }
      if(action.type=='duplicate-room' || action.type=='create-room'){
        this.selectedPlan.rooms.splice(this.selectedPlan.rooms.indexOf(action.room), 1);
        if(this.selectedPlan.firstSelection == action.room){
          this.selectedPlan.firstSelection = null;
        }
      }
      if(action.type=='delete-room'){
        this.selectedPlan.rooms.push(action.room);
        this.selectedPlan.firstSelection = action.room;
        if(action.was_fire_escape){
          this.selectedPlan.fire_escape_room = action.room.id;
        }
      }
      if(action.type=='resize-room'){
        this.selectedPlan.firstSelection = action.room;
        this.selectedPlan.firstSelection.x = action.x;
        this.selectedPlan.firstSelection.y = action.y;
        this.selectedPlan.firstSelection.width = action.width;
        this.selectedPlan.firstSelection.height = action.height;
      }
      if(action.type=='add-edge'){
        this.selectedPlan.paths[action.room1].splice(this.selectedPlan.paths[action.room1].indexOf(action.room2), 1);
        this.selectedPlan.paths[action.room2].splice(this.selectedPlan.paths[action.room2].indexOf(action.room1), 1);
        this.selectedPlan.path_info[action.room1+"+"+action.room2] = null;
        this.selectedPlan.path_info[action.room2+"+"+action.room1] = null;
        this.selectedPlan.secondSelection = null;
      }
      if(action.type=='delete-edge'){
        this.selectedPlan.paths[action.room1].push(action.room2);
        this.selectedPlan.paths[action.room2].push(action.room1);
        this.selectedPlan.path_info[action.room1+"+"+action.room2] = action.path_info;
      }

      if(action.type=='mark-as-escape'){
        this.selectedPlan.fire_escape_room = action.previous;
      }
    })

    this.$root.$on('action-delete-room', ()=>{
      if(this.selectedPlan.firstSelection != null){
        this.undoStack.push({
          type: 'delete-room',
          room: this.selectedPlan.firstSelection,
          was_fire_escape: this.selectedPlan.firstSelection.id == this.selectedPlan.fire_escape_room
        });
        this.selectedPlan.rooms.splice(this.selectedPlan.rooms.indexOf(this.selectedPlan.firstSelection), 1);

        if(this.selectedPlan.fire_escape_room == this.selectedPlan.firstSelection.id){
          this.selectedPlan.fire_escape_room = "";
        }

        this.selectedPlan.firstSelection = null;
      }
    })

    this.$root.$on('action-add-edge', (room1, room2, length=0)=>{
      if(!this.selectedPlan.paths[room1.id].includes(room2.id)){
        this.undoStack.push({
          type: 'add-edge',
          room1: room1.id,
          room2: room2.id
        });

        this.selectedPlan.paths[room1.id].push(room2.id);
        this.selectedPlan.paths[room2.id].push(room1.id);
        this.selectedPlan.path_info[room1.id+"+"+room2.id] = {
          rooms: [room1.id, room2.id],
          length: length
        }
      }
    })

    this.$root.$on('action-delete-edge', (room1, room2)=>{
      room1 = this.selectedPlan.firstSelection;
      room2 = this.selectedPlan.secondSelection;
      if(this.selectedPlan.paths[room1.id].includes(room2.id)){
        this.undoStack.push({
          type: 'delete-edge',
          room1: room1.id,
          room2: room2.id,
          path_info: this.selectedPlan.path_info[room1.id+"+"+room2.id] || this.selectedPlan.path_info[room2.id+"+"+room1.id]
        });

        this.selectedPlan.paths[room1.id].splice(this.selectedPlan.paths[room1.id].indexOf(room2.id), 1);
        this.selectedPlan.paths[room2.id].splice(this.selectedPlan.paths[room2.id].indexOf(room1.id), 1);
        this.selectedPlan.path_info[room1.id+"+"+room2.id] = null;
        this.selectedPlan.path_info[room2.id+"+"+room1.id] = null;
        this.selectedPlan.secondSelection = null;
      }
    })

    this.$root.$on('action-copy', ()=>{
      if(this.selectedPlan.firstSelection == null) return;
      let cc = this.selectedPlan.firstSelection;
      this.clipboard = {
        name: cc.name,
        x: cc.x,
        y: cc.y,
        width: cc.width,
        height: cc.height,
      };
    })

    this.$root.$on('action-cut', ()=>{
      if(this.selectedPlan.firstSelection == null) return;
      this.$root.$emit('action-copy');
      this.$root.$emit('action-delete-room');
    })

    this.$root.$on('action-paste', (x, y)=>{
      if(this.clipboard == null) return;
      this.$root.$emit('action-create-room', 
        (x == null) ? this.clipboard.x : x,
        (y == null) ? this.clipboard.y : y,
        this.clipboard.width,
        this.clipboard.height,
        this.clipboard.name,
      );
    })

    this.$root.$on('action-duplicate', ()=>{
      if(this.selectedPlan.firstSelection == null) return;
      this.$root.$emit('action-copy');
      this.$root.$emit('action-paste');
    })

    this.$root.$on('action-save', ()=>{
      if(this.project == null) return;
      var bb = new Blob([JSON.stringify(this.project)], { type: 'text/plain' });
      var a = document.createElement('a');
      a.download = this.project.name+'.fireplan.json';
      a.href = window.URL.createObjectURL(bb);
      a.click();
    })

    this.$root.$on('action-open', ()=>{
      let fileInput = document.getElementById('fileInput');
      fileInput.value = null;
      let listener = ()=>{
        fileInput.removeEventListener('change', listener);
        let file = fileInput.files[0];
        if (!file) {
          return;
        }
        var reader = new FileReader();
        reader.onload = (e)=>{
          var contents = e.target.result;
          let mJson = JSON.parse(contents);
          this.project = mJson;
          this.undoStack.clear();
          if(this.project.plans.length > 0){
            this.selectedPlan = this.project.plans[0];
          }
        };
        reader.readAsText(file);
      }
      fileInput.addEventListener('change', listener);
      fileInput.click();
    })

    this.$root.$on('create-project', (project)=>{
      this.project = project;
      this.undoStack.clear();
      if(this.project.plans.length > 0){
        this.selectedPlan = this.project.plans[0];
      }
    })

    this.$root.$on('fire-escape', (room)=>{
      if(this.selectedPlan.fire_escape_room == null) return;

      let dist = {};
      let previous = {};
      let heap = new Heap();

      for(let i = 0; i < this.selectedPlan.rooms.length; i++){
        dist[this.selectedPlan.rooms[i].id] = Number.MAX_VALUE;
        previous[this.selectedPlan.rooms[i].id] = null;
        heap.insert(this.selectedPlan.rooms[i].id == room.id ? 0 : dist[this.selectedPlan.rooms[i].id], 
          this.selectedPlan.rooms[i].id, this.selectedPlan.rooms[i].id)
      }

      dist[room.id] = 0;

      while(!heap.isEmpty()){
        let min = heap.extractMin();
        let paths = this.selectedPlan.paths[min];
        for(let j = 0; j < paths.length; j++){
          let edgeLength = this.getEdgeProperties(min, paths[j]).length;
          if(dist[paths[j]] > parseFloat(dist[min]) + parseFloat(edgeLength)){
            dist[paths[j]] = parseFloat(dist[min]) + parseFloat(edgeLength);
            previous[paths[j]] = min;
            heap.changePriority(paths[j], dist[paths[j]]);
          }
        }
      }

      let current = this.selectedPlan.fire_escape_room;
      if(previous[current] == null){
        this.route.found = false;
        return;
      }
      this.route = {
        found: true,
        distance: dist[this.selectedPlan.fire_escape_room],
        path: []
      }

      while(current != null){
        this.route.path.push(current);
        current = previous[current];
      }
    })
  },

  beforeDestroy() {
    let offs = ['action-copy','action-paste','action-cut','action-duplicate','action-save',
      'action-open','action-undo','action-create-room'];
    for(let i = 0; i < offs.length; i++){
      this.$root.$off(offs[i]);
    }
  },

  data() {
    return {
      selectedPlan: null,
      searchQuery: "",
      project: {
        name: "Building Name",
        author: "Aditya",
        plans: [
          {
            name: "Plan 1",
            fire_escape_room: null,
            rooms: [],
            paths: {},
            path_info: {},
            firstSelection: null,
            secondSelection: null,
          }
        ]
      },
      undoStack: new Stack(),
      clipboard: null,
      route: {
        distance: 0,
        path: [],
        found: false
      }
    }
  },

  computed: {
    searchPlans(){
      return this.project.plans.filter((value=>value.name.
                                  toLowerCase().includes(this.searchQuery.toLowerCase())));
    },
    firstSelection(){
      if(this.selectedPlan!=null)
      return this.selectedPlan.firstSelection;
      else return null;
    },
    edgeProperties(){
      let temp = this.selectedPlan.path_info[this.selectedPlan.firstSelection.id+"+"+this.selectedPlan.secondSelection.id]
      if(temp!=null) return temp;
      temp = this.selectedPlan.path_info[this.selectedPlan.secondSelection.id+"+"+this.selectedPlan.firstSelection.id]
      if(temp!=null) return temp;
      return null;
    }
  },
}
</script>

<style>
  .editor{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .editor-content{
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: row;
  }
  
  .editor-nav{
    width: 100%;
    height: 48px;
    background: #FFF;
    flex-shrink: 0;
    box-shadow: 0px 2px 8px rgba(0,0,0, 0.25);
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
  }

  .editor-nav-logo{
    user-select: none;
    border-radius: 50%;
    padding: 8px;
    font-size: 24px;
    transition: background 0.25s;
    cursor: pointer;
    color: var(--color-accent);
    text-decoration: none;
  }

  .editor-nav-logo:hover{
    background: rgba(0,0,0, 0.15);
  }

  .editor-nav-item{
    padding: 6px 24px;
    font-weight: 500;
    border-radius: 4px;
    font-size: 14px;
    margin: 0px 4px;
    transition: background 0.15s;
    cursor: pointer;
    user-select: none;
  }

  .editor-nav-item:hover{
    background: #fb860038;
  }

  .editor-nav-item:active{
    background: #fb860088;
  }

  .editor-sidebar{
    width: 360px;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    background: var(--color-primary);
    color: var(--color-secondary);
    padding: 32px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .editor-window{
    width: 240px;
    background: var(--color-primary);
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0,0,0, 0.25);
    color: #FFF;
    position: fixed;
    right: 32px;
    top: 64px;
    display: flex;
    flex-direction: column;
  }

  .editor-window-title{
    display: block;
    padding: 12px;
    font-weight: 500;
    font-size: 14px;
    border-bottom: solid 1px rgba(255,255,255,0.2);
  }

  .editor-window-content{
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
  }
  
  .editor-window-label{
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin-top: 4px;
  }

  .editor-window-input, .search-input{
    background: rgba(142, 202, 230, 0.25);
    border: none;
    outline: none;
    height: 32px;
    margin: 4px 0px;
    color: #FFF;
    border-radius: 4px;
    padding: 0px 12px;
    transition: all 0.1s;
    width: 100%;
    display: block;
  }

  .editor-window-input:focus, .search-input:focus{
    outline: solid 2px var(--color-accent);
  }

  .editor-window-input::placeholder, .search-input::placeholder{
    color: var(--color-secondary);
  }

  .search-row{
    display: flex;
    margin-top: 16px;
  }

  .add-plan-btn{
    background: var(--color-accent);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    color: white;
    margin: 4px 0px 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.25s;
    cursor: pointer;
  }

  .add-plan-btn:hover{
    background: #db6a00;
  }
  
  .add-plan-btn:active{
    background: #c75a00;
  }
  
  .editor-window-action{
    display: flex;
    background: var(--color-accent);
    padding: 8px 16px;
    justify-content: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 4px;
    cursor: pointer;
    transition: all 0.25s;
  }

  .editor-window-action:hover{
    background: #db6a00;
  }

  .editor-window-action:active{
    background: #c75a00;
  }

  .editor-window-split{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .editor-window-split-part{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .editor-window-split-space{
    width: 8px;
    flex-shrink: 0;
  }

  .plan-list{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: 100%;
    overflow-y: auto;
  }

  .plan-item{
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 2px;
    transition: background 0.25s;
    cursor: pointer;
  }

  .plan-item:hover{
    background: #8ecae638 !important;
  }

  .plan-item:active{
    background: #8ecae627 !important;
  }

  .plan-item.plan-item-selected{
    background: #8ecae61f;
  }

  .plan-item-title{
    color: #FFF;
    font-size: 14px;
  }

  .plan-item-subtitle{
    font-size: 12px;
  }

  .project-title{
    font-size: 24px;
    color: #FFF;
    font-weight: 600;
    letter-spacing: 0.05em;
  }

  .project-subtitle{
    font-size: 12px;
    color: var(--color-accent);
    font-weight: 500;
    letter-spacing: 0.2em;
    margin-top: 4px;
  }

  #editor-toast{
    position: fixed;
    bottom: 32px;
    right: 32px;
    background: rgba(0,0,0, 0.75);
    border-radius: 8px;
    padding: 12px 24px;
    min-width: 180px;
    text-align: left;
    color: white;
    display: none;
    opacity: 0;
  }

  @media screen and (max-width: 840px) {
    .editor-sidebar{
      display: none;
    }
  }

  @media print{
    .editor-sidebar, .editor-window, .editor-nav{
      display: none;
    }
  }
</style>